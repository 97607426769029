.hero {
  &-poster {
    position: absolute;
    z-index: -1;
    height: 496px;
    width: 100%;
    left: 0;
    top: 0px;
    background-color: black;
    img {
      height: 100%;
      width: 100%;
      opacity: 0.7;
    }
  }
  &-container {
    padding: 48px 10%;
    .container {
      padding: 0;
    }
  }

  &-content {
    &__container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: auto;
    }

    &__title {
      color: $black;
      font-size: 44px;
      font-weight: 800;
      margin-bottom: 32px;
      text-align: center;
      line-height: normal;
      letter-spacing: -0.5px;
      span {
        color: $primary;
      }
    }
    &__trending {
      margin-top: 8px;
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      &__label {
        color: $silver;
      }
      &__item {
        color: $gray;
        padding: 0px;
        padding-left: 4px;
      }
      &__item::after {
        content: ",";
      }
      &__item:last-of-type::after {
        content: " ";
      }
      &__link {
        font-weight: 400;
        color: $gray;
      }
    }
  }

  &-search {
    display: flex;
    margin: auto;
    position: relative;
    &__close-search {
      cursor: pointer;
      position: absolute;
      top: 26px;
      right: 2%;
    }
    &__input {
      display: block;
      border: 1px solid $blight;
      width: 100%;
      padding: 24px 24px 24px 64px;
      color: $black;
      font-size: 20px;
      font-weight: 700;
      background: url(../images/search-gray.svg) 24px 50% no-repeat;
      outline: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      transition: all 0.3s ease-in-out;
      transform: scale(0.995);
      z-index: 5;
      &:hover {
        border: 1px solid darken($blight, $amount: 5);
      }
      &:focus {
        transform: scale(1);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
        border: 1px solid darken($blight, $amount: 5);
      }
      &::placeholder {
        color: #9ba4bc;
      }
    }
    &__submit {
      display: block;
      padding: 25px 50px 25px 50px;
      background-color: $primary;
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }
    &-result {
      position: absolute;
      height: 0px;
      padding: 0px;
      width: 100%;
      bottom: 0px;
      z-index: 999;
      &__container {
        box-shadow: 0 5px 20px 0 rgba(155, 164, 188, 0.2);
        max-height: 500px;
        overflow: scroll;
      }
      &-caontainer {
        width: 81.5%;
        background-color: white;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
      }
      &__header {
        color: $primary;
        background-color: #fff6f1;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
      &__user {
        display: flex;
        background-color: white;
        padding: 8px 16px;
        &-avatar {
          display: block;
          height: 56px;
          width: 56px;
          margin-right: 16px;
        }
        &-icon {
          display: block;
          height: 24px;
          width: 24px;
          margin-right: 16px;
        }
        &-title {
          margin: 0px;
          padding-bottom: 16px;
        }
        &-work {
          margin: 0px;
          padding-bottom: 16px;
        }
        &-name span {
          color: #9ba4bc;
          font-weight: 500;
          & li {
            display: inline;
            &::after {
              content: ", ";
            }
            &:last-of-type::after {
              content: " ";
            }
          }
        }
      }
    }
    .get-instant-quote {
      position: absolute;
      bottom: -39px;
      right: 3px;
      border-radius: 0 0 5px 5px;
    }
  }
}

.instant-quote-modal {
  &__header {
    min-height: 45px;
  }
  &__content {
    max-width: 670px;
    position: absolute;
    right: 0;
    min-height: 100vh;
  }
  &__close {
    color: #FF8141;
    position: absolute;
    left: 25px;
    top: 4px;
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
  &__body {
    .new-booking {
      padding: 25px;
      width: auto;
    }
  }
}
.home {
  &-artists {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    background: rgb(251 251 251);
    margin-bottom: -40px;
    .btns {
      text-align: center;
      .register-artist {
        background-color: #fff3ec;
        padding: 10px 8px 10px 10px;
        margin-right: 8px;
        line-height: 1.5;
        border-radius: 5px;
        transition: all 0.3s;
        margin-left: 20px;
        color: #FF8141
      }
      .register-artist:hover {
        background-color: #ffe9dd;
      }
    }
    .header {
      text-align: center;
      margin-bottom: 50px;
      font-size: 22px;
    }
    .artists {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
    a {
      color: rgb(19 19 19);
      font-weight: normal;
    }
    ul {
      line-height: 25px;
    }
  }
  &-customers {
    h6.title {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: .5px;
      margin-bottom: 45px;
      text-transform: uppercase;
      font-variant: small-caps;
      color: #9ba4bc;
    }
  }
  &-faqs {
    h6.title {
      font-weight: 700;
      font-size: 20px;
      letter-spacing: .5px;
      margin-bottom: 45px;
      text-transform: uppercase;
      font-variant: small-caps;
      color: #9ba4bc;
    }
    .faqs {
      border-radius: 10px;
      box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.10);
      padding: 15px;
      margin-bottom: 10px;

      &-question{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        h3 {
          font-size: 18px;
          font-weight: 550;
        }
        .arrow {
          img {
            width: 22px;
          }
        }
      }

      &-answer{
        display: none;
        margin-top: 20px;
        font-size: 16px;
        line-height: 1.5;
        p {
          color: rgba(19 19 19);
        }
      }
    }
    .faqs.collapsed .faqs-answer {
      display: block;
    }
    .faqs.collapsed .arrow {
      transform: rotate(180deg);
    }
  }
}
.home {
  &-clients {
    h6.title {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: .5px;
      text-transform: uppercase;
      font-variant: small-caps;
      color: #9ba4bc;
    }

    .logos {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &-logo {
        padding: 16px;
        &__image {
          max-width: 90px;
          width: 100%;
        }
      }
    }

    .overlay-link {
      text-align: center;
      width: 100%;
      &:hover {
        transform: scale(0.99);
        opacity: 0.9;
        .see-more {
          opacity: 1;
        }
      }
      .see-more {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        border-radius: 2px;

        @media screen and (max-width: 640px) {
          opacity: 1 !important;
        }
      }
    }
  }

  &-feature {
    margin-bottom: 40px;
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      align-items: center;
      &__title {
        color: #252c3f;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
      &__options {
        font-size: 16px;
        font-weight: 600;
      }
    }
    &-main {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 20px;
      justify-content: center;
      &__user {
        &-card {
          margin-right: 2%;
          width: 15%;
          color: inherit;
          padding: 8px;
          &:last-child {
            margin: 0;
          }
        }
        &-avatar {
          height: 160px;
          width: 100%;
          border-radius: 2px;
          overflow: hidden;
        }
        &-name {
          margin: 0 0 8px 0;
          margin-top: 16px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
        }
        &-work {
          color: #9ba4bc;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.home {
  &-events {
    &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    &-title {
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-variant: small-caps;
      color: #9ba4bc;
    }
    &__navigation {
      border: 1px solid $lighter;
      width: 23.4%;
      margin-right: 2.12%;
      padding: 16px;
      border-radius: 2px;
      &-link {
        display: flex;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #454f69;
        padding: 12px;
        &:active {
          background-color: #fff6f1;
          color: #ff8141;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        &:hover {
          background-color: #fff6f1;
          color: #ff8141;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          transition: 0.4s;
        }
      }
      &-icon {
        width: 18px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: center;
      width: 74.48%;
      &-item {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 31.42%;
        height: 122px;
        margin-bottom: 24px;
        border-radius: 2px;
        overflow: hidden;
        &:not(:nth-child(3n + 3)) {
          margin-right: 2.85%;
        }
        &__content {
          width: 100%;
          padding: 8px;
          border: 1px solid $blight;
          border-top: none;
        }
        &__poster {
          width: 100%;
          height: 100%;
          position: absolute;
        }
        &__overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          background: linear-gradient(
            180deg,
            rgba(37, 44, 63, 0.05) 49.48%,
            rgba(37, 44, 63, 0.65) 100%
          );
          z-index: 10;
        }
        &__title {
          position: absolute;
          width: 100%;
          color: #ffffff;
          font-size: 16px;
          font-weight: 800;
          line-height: 24px;
          text-align: center;
          text-align: left;
          z-index: 20;
          left: 16px;
          bottom: 12px;
        }
        &__description {
          width: 100%;
          color: #9ba4bc;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .hero {
    &-poster {
      height: 460px;
    }
  }
  .hero-content__container {
    width: 93%;
  }
  .hero-search {
    width: 100%;
  }
  .home-feature-main {
    margin-bottom: 0px;
  }
  .hero-content__title {
    font-size: 40px;
  }
  .home-feature-main__user-card {
    margin-right: 3%;
    width: 31.2%;
    margin-bottom: 31px;
    &:last-child {
      margin-bottom: 0px;
    }
    &:nth-child(3) {
      margin: 0px;
      margin-bottom: 31px;
    }
  }
}

@media screen and (max-width: 640px) {
  .instant-quote-modal {
    &__content {
      max-width: none;
      width: 100%;
    }
  }
  .hero {
    &-poster {
      height: 380px;
    }
    &-search {
      &__close-search {
        top: 17px;
      }
    }
  }
  .hero-container {
    padding: 48px 0;

    .modal {
      z-index: 10;
    }
  }
  .home-feature-main__user-card {
    width: 100%;
    padding-bottom: 20px;
  }
  .hero-content__title {
    font-size: 32px;
  }
  .hero-search__input {
    padding: 16px 16px 16px 48px;
    background: url(../images/search-gray.svg) 16px 50% no-repeat;
  }
  .hero-search__submit {
    padding: 13px 50px 13px 50px;
  }
  .hero-search {
    flex-wrap: wrap;
    justify-content: center;
    &.modal {
      display: block;
      position: fixed;
      top: 56px;
      left: 0;
      background: $white;
      .hero-search-result {
        height: calc(100vh - 54px);
        position: relative;
        overflow-y: scroll;
      }
    }
  }
  .home-events {
    margin-top: 33px;
  }
  .footer-project__description {
    width: 90%;
  }
  .home-events__navigation {
    width: 100%;
  }
  .home-events__list {
    width: 100%;
    margin-top: 40px;
  }
  .home-events__list-item {
    width: 100%;
    margin-bottom: 32px;
    margin-right: 0 !important;
  }
  .hero-search-result__user-name {
    margin-bottom: 0;
  }
  .hero-search-result__user {
    align-items: center;
  }
  .hero-search-result__container {
    box-shadow: 0 2px 6px 6px #d7dae4;
    border-radius: 0 0 4px 4px;
  }
}

@media screen and (max-width: 390px) {
  .hero-content__title {
    font-size: 24px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 640px) {
  .home-events__navigation {
    border: 1px solid #dadfea;
    width: 100%;
    margin-right: 0;
    padding: 11px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 0%;
    margin-bottom: 42px;
  }
  .home-events__list {
    width: 100%;
  }
  .home-events__navigation-link {
    width: 33%;
    padding: 11px;
  }
  .home-events__list-item {
    margin-bottom: 24px;
  }
  .home-events__list-item__content {
    padding: 5px 16px;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
@media screen and (max-width: 768px) {
  .hero-content__trending{
      display: none;
  }
}
