.create_first_class {
  display: flex;
  border: 1px solid #FF8141;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 25px;
  &_sects {
    flex: 0 0 19%;
    .btn1 {
      a {
        color: white;
        font-size: 13px;
      }
    }
    .btn2 {
      background-color: #fff3ec;
      margin-right: 8px;
      line-height: 1.5;
      font-size: 13px;
      border-radius: 5px;
      transition: all .3s;
      color: #ff8141;
    }
    button {
      width: 170px;
      padding: unset;
    }
  }
  .become_teacher {
    text-align: center;
    border-right: 2px solid #ebf2f7;
    margin-bottom: 3px;
    img {
      width: 30px;
    }
    h4 {
      font-size: 15px;
      font-weight: 600;
    }
    h3 { 
      font-size: 20px;
      margin-bottom: 3px;
      color: #FF8141;

    }
  }
  .introducing_classes {
    flex: 0 0 60%;
    margin-top: auto;
    h3 { 
      font-size: 18px;
     }
    h5 {
      font-size: 13px;
      font-weight: 600;
    }
  }
}
// .introducing-class {
//   max-width: 500px;
//   min-height: 550px;
//   border-radius: 20px;
//   text-align: center;
//   .start-offering {
//     max-width: 385px;
//     text-align: center;
//     margin: auto;
//     border: 2px solid #FF8141;
//     border-radius: 5px;
//     padding: 9px;
//     h4 {
//       font-size: 19px;
//       margin-top: 5px;
//       border-bottom: 1px solid #dbe0eb;
//       padding-bottom: 20px;
//     }
//     h3 {
//       color: #FF8141
//     }
//     .create-per-hour {
//       margin-top: unset;
//       padding-top: 20px;
//       color: #FF8141
//     }
//   }
//   h3 {
//     margin-top: 50px;
//   }
//   p {
//     margin-top: 3px;
//   }
// }
.package_name {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  .fixed-rate-image {
    height: 20px;
  }
  h3 {
    margin-bottom: unset;
  }
}
.class {
  &-packages {
    padding: 24px 0 16px 24px;
    border: none;
    border-left: 4px solid #FF8141;
    border-radius: 5px;
    box-shadow: 0px 10px 30px rgba(147, 147, 147, 0.1019607843);
    & + .class-packages {
      margin-top: 24px;
    }
    &-price__btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 30px;
        height: 30px;
      }
    }
    &-info{
      flex: 0 0 85%;
      &__list{
        &-item {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
        }
      }
      &__items { 
        display: flex;
        justify-content: space-between;
        &-info {
          display: flex;
          flex: 0 0 48%;
          flex-direction: column;
          justify-content: space-between;
          &__start_time {
            display: flex;
          }
          .description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            font-size: 16px;
            line-height: 1.3;
            max-height: calc(1.5em* 3);
          }
          .additional-information {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            font-size: 12px;
            line-height: 1.3;
            max-height: calc(1.5em* 3);
          }
          .start_time {
            border-radius: 8px;
            box-shadow: 0px 2px 4px 1px rgba(0,0,0,.1);
            font-weight: 500;
            text-align: center;
            color: #9BA4BC;
            background-color: #fff;
            cursor: pointer;
            transition: border-color 0.3s, color 0.3s;
            p {
              font-size: 10px;
            }
          }
          .start_time_width {
            width: 73px;
          }
          &__start_time {
            display: flex;
            flex-wrap: wrap;
          }
          // &__age_requirement {
          //   margin-left: 52px;
          // }
          // &__time {
          //   margin-left: 100px;
          // }
          // &__skill_level {
          //   flex: 0 0 15%;
          // }
        }
      }
    }
  }
  &-packages-price{
    display: flex;
    text-align: left;
    justify-content: space-between;
    margin-right: 10px;
    h4 {
      color: #ff8141;
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 1150px) {
  .create_first_class {
    display: flex;
    &_sects {
      flex: 0 0 20%;
      .btn1 {
        a {
          font-size: 10px;
        }
      }
      .btn2 {
        font-size: 10px;
      }
      button {
        width: 110px;
        min-width: unset;
        height: 30px;
      }
    }
    .become_teacher {
      h3 {
        font-size: 14px;
        line-height: 13px;
      }
      h4 {
        font-size: 9px;
        line-height: 12px;
        
      }
    }
    .introducing_classes {
      flex: 0 0 62%;
      h3,h5 {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .class {
    &-packages {
      &-info {
        &__items{
          p {
            font-size: 13px;
          }
          .description {
            font-size: 13px;
          }
          &-info {
            .start_time_width {
              width: 60px;
              height: 30px;
              p {
                font-size: 8px;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }
  .package_name {
    .fixed-rate-image {
      height: 15px;
    }
    h3 {
      font-size: 20px;
    }
  }
  .classes { 
    &-info {
      &--package-web {
        display: block;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .create_first_class {
    display: flex;
    &_sects {
      .btn1 {
        a {
          font-size: 7px;
        }
      }
      .btn2 {
        font-size: 8px;
      }
      button {
        width: 70px;
        height: 28px;
      }
    }
    .become_teacher {
      img {
        width: 20px;
      }
      h3 {
        font-size: 11px;
        line-height: 12px;
      }
      h4 {
        font-size: 8px;
        line-height: 10px;
      }
    }
    .introducing_classes {
      flex: 0 0 58%;
      h3,h5 {
        font-size: 8px;
        line-height: 10px;
      }
    }
  }
  .class {
    &-packages {
      &-info {
        &__items{
          p {
            font-size: 11px;
          }
          .description {
            font-size: 11px;
          }
          &-info {
            .start_time_width {
              width: 53px;
              height: 25px;
              p {
                font-size: 7px;
                line-height: 12px;
              }
            }
          }
        }
      }
      &-price{
        h4 {
          color: #ff8141;
          font-size: 13px;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .create_first_class {
    display: flex;
    &_sects {
      .become_teacher {
        img {
          height: 20px;
          width: 22px;
        }
      }
      .btn1 {
        a {
          width: 62px;
          font-size: 7px;
          height: 23px;
        }
      }
      .btn2 {
        font-size: 7px;
      }
      button {
        width: 60px;
        height: 23px;
      }
    }
  }
}