.filters {
  border-bottom: 1px solid #dbe0eb;

  .get_quotes {
    position: absolute;
    right: 0;
    top: -1px;
    background: #FF8141;
    border: 1px solid #FF8141;
    padding: 10px 16px;
    border-radius: 2px;
    color: #fff;
    font-weight: 500;
    font-size: unset;
  }
  h5 {
    margin-top: 15px;
  }
}

.filter-items {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.filter-item {
  padding: 10px;
  width: max-content;
}

.discover-page-seo {
  h1 {
    font-size: 24px;
    line-height: 32px;
  }
  margin-top: 15px;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 25px 10px 25px;
  box-shadow: 0px 2px 10px 3px #d7dae4;
  position: relative;
  &-description-background {
    overflow:hidden;
    transition: max-height 0.5s ease;
    height: auto;
    max-height: 50px;
    border-radius: 5px;
    margin-top: 15px;
    padding-bottom: 15px
  }
  &-description {
    white-space: pre-line;
    line-height: 1.5;
  }
  .arrow-down-black {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    position: absolute;
    top: 80px;
    right: 0;
  }
}
.booking-protection {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-radius: 0px 0px 10px 10px;
  font-size: 15px;
  padding: 10px;
  color: #FF8141;
  background: rgb(255 243 236);
  box-shadow: 0px 2px 3px 2px rgb(255, 243, 236);
  font-weight: 600;
  margin-bottom: 20px;
  .quality-artists {
    display: flex;
  }
  .arrow-forward-orange {
    display: none;
  }
}

.transform {
  transition: transform 0.5s;
  transform:rotate(180deg);
  -ms-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  
}

.active {
  max-height: unset;
}

.discover {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-wrap: wrap;

  &-search {
    display: none;
  }

  &-countries {
    display: none;
  }

  &-avatar-container {
    position: relative;
    width: 100%;
    padding: unset;

    .pro-member-badge {
      width: 100px;
      height: 35px;
      position: absolute;
      bottom: -4px;
      right: -8px;
    }
  }

  &-pagination {
    flex: 0 0 100%;
    margin-top: 40px;
  }

  &-breadcrumbs {
    span {
      position: relative;
      padding-right: 10px;
      display: inline-block;
    }
    span::after {
      content: ">";
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px;
    }
    span:last-child::after {
      content: "";
      padding: 0;
    }
  }

  &-list {
    flex: 0 0 75%;
    min-height: 500px;
    margin-right: auto;

    .international-options-btn {
      margin-left: -10px;
    }
  }

  &-icon {
    background: url(../images/pro-member-tag.svg) 0 50% no-repeat;
    background-size: 20px;
    width: fit-content;
  }

  &-filter {
    display: none;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
    border-bottom: 1px solid $blight;
    cursor: pointer;

    &-image {
      background: url(../images/filters.svg) 50% 50% no-repeat;
      padding: 20px;
      border-radius: 10px;
      margin-left: 5px;
      margin-bottom: 15px;
      background-color: rgb(239, 244, 250);
      box-shadow: 0 2px 5px 2px #d7dae4;
    }

    h3 {
      margin: 0;
      padding-left: 32px;
      background: url(../images/filters.svg) 0 50% no-repeat;
    }

    &__info {
      color: $silver;
    }

    &__inputs {
      .multiselect__tags {
        box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.17);
        border: none;
        border-radius: 3px;
      }
    }

    &-mobile__inputs {
      margin: 0px 0 15px 0px;
      border: none;
      border-radius: 8px;
      width: 100%;
      padding: 8px 8px 8px 40px;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      width: 100%;
      background: url(../images/search-gray.svg) 8px 50% no-repeat;
      box-shadow: 0 2px 5px 2px #d7dae4;

      .multiselect__tags {
        box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.17);
        border: none;
        border-radius: 3px;
        background: #9ba5bc;
        width: 100%;
      }
    }
  }

  &-item {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 7px 3px #d7dae4;
    //box-shadow: 0 2px 3px 2px #d7dae4;
    background-color: #fff;
    &:first-child {
      margin-top: 15px;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &__avatar {
      width: 200px;
      height: 200px;
      padding: unset;
      margin-right: 12px;
      border-radius: 8px;
      box-shadow: 0 3px 3px hsl(0deg 0% 58% / 10%);
      webkit-box-shadow: 0 3px 3px hsl(0deg 0% 58% / 10%);
    }

    &__info {
      flex-grow: 1;
      padding-top: 8px;
    }

    &__name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 24px;
      line-height: 25px;
      overflow: hidden;
      margin-bottom: 16px;
    }

    &__rating {
      margin-top: 7px;

      .star {
        height: 20px;
        padding-top: 3px;
      }
    }

    &__specialities {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 8px 0 16px 0;
      flex-wrap: wrap;
      font-size: 14px;
      color: $silver;
      line-height: 20px;

      li {
        padding-right: 4px;
      }

      li::after {
        content: ",";
      }

      :last-child::after {
        content: "";
      }
    }

    &__price {
      align-self: flex-end;
      padding-bottom: 20px;
      padding-right: 20px;

      &_info {
        text-align: start;
        border-radius: 5px;

        h2 {
          font-size: 20px;
          line-height: 32px;
        }
      }

      &_info_mobile {
        display: none;
      }

      p {
        color: $silver;
      }

      h3 {
        font-weight: 600;
      }
    }

    &__more {
      color: white;
      font-size: 15px;
      background: #ff8141;
      text-align: center;
      border-radius: 5px;
      width: 162px;
      padding: 10px 17px 10px 17px;
    }

  }

  &__first-section {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    background: #f6f7f8;
    h2 {
      font-size: 24px;
      line-height: 32px;
      line-height: 32px;
      margin-bottom: 7%;
      margin-top: 3%;
      padding-top: 3%;
    }
    .mobile-answer { 
      display: none;
    }
    .container {
      .questions:last-child {
        margin-bottom: 3%;
      }
    }
    .questions {
      margin: 7% 0;
      display: flex;
      .answer { 
        display: block;
      }
      img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }
      h4 {
        width: 50%;
        padding-top: 5px;
      }
      p {
        width: 55%;
      }
    }
  }

  &__second-section {
    h2 {
      font-size: 24px;
      line-height: 32px;
    }
    .show_more_button {
      display: inline-block;
      color: #9ba4bc;
      font-size: 13px;
      cursor: pointer; 
    }
    .reviews {
      display: flex;
      flex-direction: row;
      .review {
        margin-right: 20px;
        margin-left: unset;
        margin-top: unset;
        .rating-star {
          display: flex;
          .img {
            width: 20px;
          }
        }
        .image-container {
          position: relative;
          width: 30px;
          height: 30px;
          display: inline-block;
          .profile-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
          
          .google-logo {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%; 
          }
        }
        .name-info {
          height: 40px;
          margin-left: 6px;
          .name {
            font-size: 13px;
          }
          .time {
            font-size: 10px;
            position: relative;
            top: -15px;
          }
        }
        .blue-tick {
          width: 20px;
          height: 20px;
        }
        .message {
          line-height: normal;
          color: black;
          width: 100%;
          font-size: 13px;
        }
        .read-more { 
          color: rgb(155, 164, 188);
          font-size: 15px;
        }
      }
    }
  }

  &-sidebar {
    flex: 0 0 23.4%;

    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      p {
        cursor: pointer;
      }
    }

    &__apply, &__clear, &__mobile-instant-quotes {
      display: none;
    }

    &__close {
      display: none;
    }
  }
}


@media screen and (max-width: 1024px) {
  .discover {
    &-list {
      flex-basis: 100%;

      .international-options-btn {
        margin-left: 0;
      }
    }

    &-filter {
      display: flex;
    }

    &-sidebar {
      display: none;
      flex-direction: column;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 40px;
      padding-top: 5px;
      background: $white;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      transition: all 1s;

      &.show {
        display: flex;
        z-index: 1000;

        .forms {
          width: 100%;
        }

        .custom-input-wrap.forms__item-wp.forms__item-100,
        .forms__item.forms__item-100 {
          flex: 0 0 49.5% !important;
        }
      }

      h3 {
        position: relative;
        padding-left: 32px;
      }

      &__filter {
        flex: 0 0 48.27%;
      }

      &__apply, &__clear, &__mobile-instant-quotes {
        display: block;
      }

      &__close {
        display: block;
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        right: 140px;
      }
    }

    &-sidebar-wrap {
      max-height: 490px;
      overflow-y: scroll;
    }

    &__first-section {
      h3 {
        font-size: 22px;
      }
      .questions {
        .answer { 
          font-size: 14px;
        }
        img {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }
        h4 {
          font-size: 15px;
        }
      }
    }

    &__second-section {
      .reviews {
        .review {
          .rating-star {
            .img {
              width: 18px;
            }
          }
          .image-container {
            width: 28px;
            height: 28px;
            display: inline-block;
            .google-logo {
              width: 13px;
              height: 13px;
            }
          }
          .name-info {
            .name {
              font-size: 10px;
            }
            .time {
              font-size: 9px;

            }
          }
          .blue-tick {
            width: 18px;
            height: 18px;
          }
          .message {
            width: 100%;
            font-size: 10px;
          }
        }
      }
    }
  }
  .discover-page-seo {
    &-description-background {
      max-height: 45px;
    }
    &-description {
      font-size: 14px;
      line-height: 1.8;
    }
    .arrow-down-black {
      width: 20px;
      height: 20px;
    }
  }
  .booking-protection {
    font-size: 13px;
    padding: 10px;
    img {
      width: 20px;
    }
  }
  .active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .discover{
    &-sidebar {
      &__content {
        .hide-follow-steps {
          display: none;
        }
      }
    }
    &__second-section {
      .reviews {
        flex-direction: column;
        .review {
          margin-right: 20px;
          margin-left: 20px;
          margin-top: 20px;
          .name-info {
            .name {
              font-size: 13px;
            }
            .time {
              font-size: 10px;

            }
          }
          .message {
            font-size: 13px;
          }
        }
      }
    }
    &__first-section {
      h3 {
        margin-left: 30px;
      }
      .mobile-answer { 
        display: block;
        margin: 0 30px 0 30px;
      }
      .questions {
        margin-left: 30px;
        margin-bottom: 3%;
        .answer { 
          display: none;
          font-size: 14px;
        }
        img {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }
        h4 {
          width: unset;
        }
      }
    }
  }
  .discover-page-seo {
    h1 {
      font-size: 20px;
    }
    &-description-background {
      max-height: 35px;
      margin-top: unset;
    }
    &-description {
      font-size: 10.5px;
      a {
        font-size: 10.5px;
      }
    }
    .arrow-down-black {
      width: 18px;
      height: 18px;
      top: 60px;
    }
  }
  .active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 250px;
  }
  .booking-protection {
    font-size: 11px;
    padding: 7px;
    .quality-artists {
      display: none;
    }
    .arrow-forward-orange {
      display: block;
    }
    img { 
      width: 17px;
    }
  }
}

@media screen and (max-width: 640px) {
  .discover {
    padding-left: 0;

    &-search {
      display: flex;
      margin: 0 16px 0 0;
      width: 264px;
      position: relative;
    }

    &-counrties {
      padding-bottom: 10px;
      display: flex;
      padding-top: 20px;
      color: #9ba4bc;
      font-weight: 700;
      font-weight: 700;
      font-size: 18px;

      &-model {
        color: #FF8141;
        padding: 0;
        font-weight: 700;
        font-size: 18px;
        padding-left: 6px;
      }
    }

    .mobile-avatar {
      margin-left: 5px;
    }

    &-item {
      height: 175px;
      display: flex;
      justify-content: left;
      margin-left: 0;

      &__avatar {
        margin-top: auto;
        margin-bottom: auto;
        width: 168px;
        height: 168px;
      }

      &__price {
        display: none;

        &_info_mobile {
          display: block;

          p {
            font-size: 12px;
          }

          h2 {
            font-size: 16px;
            font-weight: 600;
            color: #ff8141;
            margin-bottom: 5px;
          }
        }
      }

      &__desc {
        line-height: 18px;
        max-width: none;
        font-size: 12px;
        margin-bottom: 8px;
      }

      &__rating {
        margin-top: 5px;

        .link {
          font-size: 12px;
        }

        .star {
          height: 12px;
          padding-top: 0px;

          img {
            height: inherit;
          }
        }
      }

      &__info {
        padding-top: 0px;
        margin-top: -5px;
      }

      &__name {
        margin-bottom: 5px;
        font-size: 20px;
        margin-top: 15px;
      }

      &__specialities {
        margin: 0;
        font-size: 10px;
      }
    }

    &-sidebar {
      &__content {
        overflow-y: auto;
        max-height: calc(100vh - 110px);

        .hide-follow-steps {
          display: none;
        }
      }

      &__filter {
        flex: 0 0 100%;
      }

      &__apply, &__clear, &__mobile-instant-quotes {
        display: block;
      }

      &__close {
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 15px;

        .discover-filter {
          border-bottom: none;

          h3 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .discover-page-seo {
    h1 {
      font-size: 18px;
    }
    &-description-background {
      max-height: 33px;
    }
    &-description {
      font-size: 9px;
      a {
        font-size: 9px;
      }
    }
  }
  .active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .discover {
    &-avatar-container {
      .pro-member-badge {
        width: 80px;
        height: 35px;
        bottom: -5px;
        right: -2px;
      }
    }

    &-item {
      &__avatar {
        flex: 0 0 150px;
        width: 150px;
        height: 150px;
      }

      &__price {
        text-align: left;

        h3 {
          color: #ff8141;
          font-size: 16px;
          display: inline-block;
          margin-bottom: 3px;
        }

        p {
          font-size: 16px;
          display: inline-block;
          padding-right: 4px;
        }
      }

      &__desc {
        max-width: none;
        margin-bottom: 0px;
        font-size: 12px;
        color: #9ba5bc;
      }

      &__rating {
        .link {
          font-size: 12px;
        }
      }

      &__info {
        padding-top: 0px;
      }

      &__name {
        margin-bottom: 0;
        font-size: 20px;
      }

      &__specialities {
        margin: 3px 0 3px 0;
      }
    }
  }
}



